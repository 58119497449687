import { graphql } from "gatsby"
import React from "react"
import BrandsGrid from "../components/brands-grid"
import Footer from "../components/footer"
import BrandCategoryBox from "../components/brand-category-box"
import Header from "../components/header"
import SEO from "../components/seo"
import GenericContent from "../components/generic-content.js"
import styles from "../components/generic-content.module.scss"
import brandStyles from "./brand.module.scss"
import MarkdownIt from "markdown-it"
import Img from "gatsby-image"

export default class BrandTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openPopup: null,
    }
  }
  buildBrandBox(index) {
    const { brandBox } = {
      ...this.props.data.brands.frontmatter,
    }
    if (brandBox) {
      return <BrandCategoryBox data={brandBox[index]}></BrandCategoryBox>
    }
  }
  buildPopup(index) {
    const md = new MarkdownIt()
    const { brandBox } = {
      ...this.props.data.brands.frontmatter,
    }

    const title = this.props.data.brands.frontmatter.title
    const isOpen = () => {
      return this.state.openPopup === index ? "" : "hide"
    }
    const renderPopupCopy = () => {
      if (brandBox) {
        const popupCopy = brandBox[index].popupCopy
        if (popupCopy) {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: md.render(popupCopy),
              }}
            ></div>
          )
        }
      }
    }
    return (
      <div className={`modal-container brand-category-box ${isOpen()}`}>
        <div className="modal">
          <a onClick={() => this.closePopup(index)} className="modal-close">
            Close
          </a>
          <div className={`${styles.genericContent} clearfix`}>
            <div className="copy">
              <h3>{title}</h3>
              {renderPopupCopy()}
            </div>
            <div className="images">
              <div className={`brands-popup-cross-fade`}>
                {this.buildPopupImages(brandBox, index)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  buildPopupImages(images, index) {
    if (images) {
      const imagesArray = []
      console.log("images", images[index].images)
      if (images[index].images !== null) {
        images[index].images.forEach((el, index) => {
          if (
            el &&
            el.image &&
            el.image.childImageSharp &&
            el.image.childImageSharp.fluid
          ) {
            imagesArray.push(
              <div className="image" key={index}>
                <Img fluid={el.image.childImageSharp.fluid} alt={el.imageAlt} />
              </div>
            )
          }
        })
      }
      return imagesArray
    }
  }
  closePopup() {
    this.setState({
      openPopup: null,
    })
  }
  openPopup(index) {
    this.setState({
      openPopup: index,
    })
  }
  render() {
    const { title, description, metaImage, mainCopy } = {
      ...this.props.data.brands.frontmatter,
    }
    return (
      <div>
        <SEO
          title={title}
          description={description}
          metaImage={metaImage}
          pathname={this.props.location.pathname}
        />
        <Header></Header>
        <main className={`grid ${brandStyles.main}`}>
          <div className={`${brandStyles.copy}`}>
            <div className="padding-s">
              <div className="padding-t-s">
                <h1>{title}</h1>
              </div>
              <GenericContent
                flex="false"
                padding="padding-s padding-l-0"
                content={mainCopy}
              ></GenericContent>
            </div>
          </div>
          <div className={`${brandStyles.boxes}`}>
            <div className={`${brandStyles.box}`}>
              <div
                className="popupOpenTrigger"
                onClick={event => {
                  if (event.target.classList.value !== "modal-close") {
                    this.openPopup(0)
                  }
                }}
              >
                {this.buildBrandBox(0)}
                {this.buildPopup(0)}
              </div>
              <div
                className="popupOpenTrigger"
                onClick={event => {
                  if (event.target.classList.value !== "modal-close") {
                    this.openPopup(1)
                  }
                }}
              >
                {this.buildBrandBox(1)}
                {this.buildPopup(1)}
              </div>
              <div
                className="popupOpenTrigger"
                onClick={event => {
                  if (event.target.classList.value !== "modal-close") {
                    this.openPopup(2)
                  }
                }}
              >
                {this.buildBrandBox(2)}
                {this.buildPopup(2)}
              </div>
              <div
                className="popupOpenTrigger"
                onClick={event => {
                  if (event.target.classList.value !== "modal-close") {
                    this.openPopup(3)
                  }
                }}
              >
                {this.buildBrandBox(3)}
                {this.buildPopup(3)}
              </div>
            </div>
          </div>
          <div className={`${brandStyles.brands}`}>
            {<BrandsGrid></BrandsGrid>}
          </div>
        </main>
        <Footer></Footer>
      </div>
    )
  }
}

export const query = graphql`
  query($slug: String!) {
    brands: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        logo {
          childImageSharp {
            fixed(width: 300) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        metaImage {
          childImageSharp {
            fixed(width: 600) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        mainCopy
        brandBox {
          heading
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageAlt
          subHeading
          popupCopy
          images {
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            imageAlt
          }
        }
      }
      fields {
        slug
      }
    }
  }
`
