import Img from "gatsby-image"
import React from "react"
import styles from "./brand-category-box.module.scss"

export default class BrandCategoryBox extends React.Component {
  render() {
    const { heading, image, imageAlt, subHeading } = this.props.data
    const fluidImage =
      image && image.childImageSharp && image.childImageSharp.fluid !== null ? (
        <Img fluid={image.childImageSharp.fluid} alt={imageAlt} />
      ) : null
    return (
      <div className={styles.box}>
        <div className={styles.image}>{fluidImage}</div>
        <div className={styles.textHolder}>
          <h2 className={styles.heading}>{heading}</h2>
          <h3 className={styles.subHeading}>{subHeading}</h3>
        </div>
      </div>
    )
  }
}
